import React, {useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API} from "../api";
import {Button, Modal} from "reactstrap";

import LandingPageHeader from "./LandingPageHeader";

import {LoginModal} from "./LoginModal";


export function ConfirmEmailModal() {
    const [smallModal] = React.useState(true);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const {key} = useParams()



    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        axios.post(API.auth.verifyEmail, {key})
            .then(res => {
                setSuccess(true)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <LandingPageHeader/>
            {loading && "Loading..."}
            <Modal
                isOpen={smallModal}
                className="modal-sm"
                modalClassName="bd-example-modal-sm"
            >
                <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                        <h4>Click the verify button below to activate your account:</h4>
                        <br/>
                        <Button block className="btn-round" color="warning" type="submit">
                            Activate account
                        </Button>
                    </div>
                    <div className="modal-body" align="center">
                        {success && "Your e-mail has been verified! You can now"} {success ? <LoginModal/> : ""}
                    </div>
                </form>
            </Modal>
        </>
    );
}
