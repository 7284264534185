import React from "react";
// reactstrap components
import {Button, Modal} from "reactstrap";
import {EstateDelete} from "./EstateDelete";

export function DeleteModal() {
    const [liveDemo, setLiveDemo] = React.useState(false);
    return (
        <>
            <Button color="danger" type="button" block
                    className="btn-round" onClick={() => setLiveDemo(true)}>
                <i className="fa fa-eraser"/> Delete
            </Button>
            <Modal isOpen={liveDemo} toggle={() => setLiveDemo(false)}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLiveLabel">
                        Listing Delete
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setLiveDemo(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>Are you sure you want to delete this Listing?</p>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setLiveDemo(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="divider"/>
                    <div className="right-side">
                        <Button
                            className="btn-link"
                            color="danger"
                            type="button"
                        >
                            <EstateDelete/>
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
