import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import LandingPageHeader from "components/LandingPageHeader.js";
import FooterBlack from "./FooterBlack";
import SectionContactUs from "./SectionContactUs";
function LandingPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });

    return (
        <>
            <LandingPageHeader/>
            <div className="wrapper">
                <div className="section text-center landing-section">
                    <Container>
                        <div>
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="8">
                                        <h2 className="title text-center">Our Latest Sold Dream Estates</h2>
                                        <br/>
                                    </Col>
                                </Row>
                                <Container>
                                    <Row>
                                        <Col md="6">
                                            <Card className="card-plain card-blog text-center">
                                                <div className="card-image">
                                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                        <img
                                                            alt="..."
                                                            className="img img-raised"
                                                            src={require("assets/img/sections/luxurious-apartment.jpg")}
                                                        />
                                                    </a>
                                                </div>
                                                <CardBody>
                                                    <span
                                                        className="mr-1 badge badge-warning badge-pill">Cityside</span>
                                                    <CardTitle tag="h3">
                                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                            City center luxurious apartment
                                                        </a>
                                                    </CardTitle>
                                                    <p className="card-description">
                                                        Take a tour on this mesmerizing 11th floor two-tone luxurious
                                                        apartment,
                                                        featuring ocean view and a glamorous platinum coated spiral
                                                        staircase.
                                                    </p>
                                                    <br/>
                                                    <Button
                                                        className="btn-default"
                                                        color="danger"
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        SOLD
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6">
                                            <Card className="card-plain card-blog text-center">
                                                <div className="card-image">
                                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                        <img
                                                            alt="..."
                                                            className="img img-raised"
                                                            src={require("assets/img/sections/luxurious-lakeside-house.jpg")}
                                                        />
                                                    </a>
                                                </div>
                                                <CardBody>
                                                    <span
                                                        className="mr-1 badge badge-warning badge-pill">Lakeside</span>
                                                    <CardTitle tag="h3">
                                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                            Relaxing lakeside three-store house
                                                        </a>
                                                    </CardTitle>
                                                    <p className="card-description">
                                                        Embrace the serenity of this splendid lakeside property resting
                                                        on top of a calm riverbed,
                                                        it features an all-season outdoor heated pool & jacuzzi.
                                                    </p>
                                                    <br/>
                                                    <Button
                                                        className="btn-default"
                                                        color="danger"
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        SOLD </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Card className="card-plain card-blog text-center">
                                                <div className="card-image">
                                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                        <img
                                                            alt="..."
                                                            className="img img-raised"
                                                            src={require("assets/img/sections/luxurious-country-house.jpg")}
                                                        />
                                                    </a>
                                                </div>
                                                <CardBody>
                                                    <span
                                                        className="mr-1 badge badge-warning badge-pill">Countryside</span>
                                                    <CardTitle tag="h3">
                                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                            Spacious countryside Villa by the hills
                                                        </a>
                                                    </CardTitle>
                                                    <p className="card-description">
                                                        Lose yourself in this serenely and premium located Villa,
                                                        featuring two-guest houses
                                                        for surprise visits! Embark on this journey to become one with
                                                        nature.
                                                    </p>
                                                    <br/>
                                                    <Button
                                                        className="btn-default"
                                                        color="danger"
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        SOLD
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6">
                                            <Card className="card-plain card-blog text-center">
                                                <div className="card-image">
                                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                        <img
                                                            alt="..."
                                                            className="img img-raised"
                                                            src={require("assets/img/sections/luxurious-beach-villa.jpg")}
                                                        />
                                                    </a>
                                                </div>
                                                <CardBody>
                                                    <span
                                                        className="mr-1 badge badge-warning badge-pill">Beachside</span>
                                                    <CardTitle tag="h3">
                                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                            Modern beachside property
                                                        </a>
                                                    </CardTitle>
                                                    <p className="card-description">
                                                        Enjoy the peace and serenity in this modern and cozy
                                                        three-stored beachhome
                                                        with private access to its own beach front.
                                                    </p>
                                                    <br/>
                                                    <Button
                                                        className="btn-default"
                                                        color="danger"
                                                        href="#pablo"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        SOLD
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                                <Button caret
                                        className="btn-default"
                                        size="lg"
                                        color="warning"
                                        href="#"
                                >
                                    See our available listings
                                </Button>
                            </Container>
                        </div>
                    </Container>
                </div>
                <SectionContactUs/>
            </div>
            <div>
                <FooterBlack/>
            </div>
        </>
    );
}

export default LandingPage;
