import React, {useState} from "react";
import {Formik, Field, Form} from 'formik';
import axios from "axios";
import {API} from "../api";
import {Button, FormGroup, Input, Modal} from "reactstrap";


export function SignUpModal() {
    const [loginModal, setLoginModal] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)


    function handleSubmit(values, {resetForm}) {
        console.log(values)
        setLoading(true)
        axios.post(API.auth.signup, values)
            .then(res => {
                resetForm()
                setSuccess(true)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Button
                className="btn-round"
                color="warning"
                type="button"
                onClick={() => setLoginModal(true)}
            >
                Sign-Up
            </Button>
            <Modal
                isOpen={loginModal}
                toggle={() => setLoginModal(false)}
                modalClassName="modal-register"
            >
                <div className="modal-header no-border-header text-center">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setLoginModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h6 className="text-muted">Welcome!</h6>
                    <h3 className="modal-title text-center">DREAM ESTATE SIGN-UP</h3>
                    <p>Enter your sign-up information below to create an account:</p>
                    <h6 className="text-center">{success && "Success! You will receive a verification e-mail to confirm your account."}</h6>
                </div>
                <div className="modal-body">
                    {loading && "Loading..."}
                    <Formik
                        initialValues={{
                            email: '',
                            password1: '',
                            password2: '',

                        }}
                        onSubmit={handleSubmit}>

                        {({errors, touched}) => (
                            <Form>
                                <FormGroup>
                                    <label>E-mail:</label>
                                    <Field
                                        id="email"
                                        name="email"
                                    >{({field}) => <Input {...field} type="email"
                                                          placeholder="Enter your e-mail"
                                                          required
                                                          invalid={errors.email && touched.email}/>}</Field>
                                </FormGroup>
                                <FormGroup>
                                    <label>Password:</label>
                                    <Field
                                        id="password1"
                                        name="password1"
                                    >{({field}) => <Input {...field} type="password"
                                                          placeholder="Enter your desired password (minimum 8 characters)"
                                                          required
                                                          invalid={errors.password1 && touched.password1}/>}</Field>
                                </FormGroup>
                                <FormGroup>
                                    <label>Confirm Password</label>
                                    <Field
                                        id="password2"
                                        name="password2"
                                    >{({field}) => <Input {...field} type="password"
                                                          placeholder="Confirm your password above"
                                                          required
                                                          invalid={errors.password2 && touched.password2}/>}</Field>
                                </FormGroup>
                                <Button block className="btn-round" color="warning" type="submit">
                                    Sign-Up
                                </Button>
                                <br/>
                                {/*<h6>{success && "You will receive a verification e-mail"}</h6>*/}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="modal-footer no-border-footer">
                    {/*<span className="text-muted text-center">*/}
                    {/*  Looking{" "}*/}
                    {/*    <a href="#pablo" onClick={e => e.preventDefault()}>*/}
                    {/*    create an account*/}
                    {/*  </a>*/}
                    {/*  ?*/}
                    {/*</span>*/}
                </div>
            </Modal>
        </>
    );
}
