import React, {useEffect} from "react";
// react plugin that creates an input with badges
import TagsInput from "react-tagsinput";

// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, FormFeedback,
} from "reactstrap";


// core components


// icons
import {
    MdOutlineBedroomChild,
    MdOutlinePool,
    MdOutlineVilla
} from "react-icons/md";
import {BiBath} from "react-icons/bi";
import {RiGlobeLine} from "react-icons/ri";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import axios from "axios";
import {API} from "../api";
import {Formik, Form, Field} from "formik";
import {GiCctvCamera, GiHomeGarage, GiResize} from "react-icons/gi";

function ImagePreview({uploadFile}) {
    const [imageSrc, setImageSrc] = useState(null)

    useEffect(() => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setImageSrc(reader.result)
        }
        reader.readAsDataURL(uploadFile)
    })
    return (
        <div>
            {!imageSrc && "Loading..."}
            {imageSrc && (
                <img src={imageSrc} alt={uploadFile.name} style={{width: '20rem', height: '20rem'}}/>
            )}
        </div>
    )
}

function ImagePreview2({uploadFile2}) {
    const [imageSrc2, setImageSrc2] = useState(null)

    useEffect(() => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setImageSrc2(reader.result)
        }
        reader.readAsDataURL(uploadFile2)
    })
    return (
        <div>
            {!imageSrc2 && "Loading..."}
            {imageSrc2 && (
                <img src={imageSrc2} alt={uploadFile2.name} style={{width: '20rem', height: '20rem'}}/>
            )}
        </div>
    )
}

function ImagePreview3({uploadFile3}) {
    const [imageSrc3, setImageSrc3] = useState(null)

    useEffect(() => {
        const reader = new FileReader()
        reader.onloadend = () => {
            setImageSrc3(reader.result)
        }
        reader.readAsDataURL(uploadFile3)
    })
    return (
        <div>
            {!imageSrc3 && "Loading..."}
            {imageSrc3 && (
                <img src={imageSrc3} alt={uploadFile3.name} style={{width: '20rem', height: '20rem'}}/>
            )}
        </div>
    )
}


export function EstateCreateView() {
    const navigate = useNavigate()
    const [uploadFile, setUploadFile] = useState(null)
    const [uploadFile2, setUploadFile2] = useState(null)
    const [uploadFile3, setUploadFile3] = useState(null)
    const [loading, setLoading] = useState(false)
    const {user} = useContext(AuthContext)
    let token = ""
    if(user) {
        token = user.token
    }

    function handleSubmit(values) {
        setLoading(true)
        const data = new FormData()
        data.append('image', uploadFile)
        data.append('image2', uploadFile2)
        data.append('image3', uploadFile3)
        data.append('title', values.title)
        data.append('description', values.description)
        data.append('location', values.location)
        data.append('num_bedrooms', values.num_bedrooms)
        data.append('num_bathrooms', values.num_bathrooms)
        data.append('price', values.price)
        data.append('square_footage', values.square_footage)
        data.append('available', values.available)
        data.append('pool', values.pool)
        data.append('garage', values.garage)
        data.append('cctv', values.cctv)
        axios.post(API.estates.create, data, {
            headers: {
                "Authorization": `Token ${token}`,
                "Content-type": "multipart/form-data",
            }
        })
            .then(res => {
                navigate(`/listings`)
                console.log(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {/*<MultiDropdownNavbar />*/}
            <div className="main">
                <div className="section">
                    <Container>
                        <div>
                            <Row>
                                <Col md="3" sm="3">
                                </Col>
                                <Col md="7" sm="7">
                                    <h2>Add Listing</h2>
                                    <br/>
                                    {loading && "Loading..."}
                                    <Formik
                                        initialValues={{
                                            title: '',
                                            image: "",
                                            image2: "",
                                            image3: "",
                                            price: '',
                                            num_bedrooms: '',
                                            num_bathrooms: '',
                                            square_footage: '',
                                            location: '',
                                            description: '',
                                            available: true,
                                            cctv: false,
                                            pool: false,
                                            garage: false,

                                        }}
                                        onSubmit={handleSubmit}>

                                        {({errors, touched, handleBlur, handleChange,}) => (
                                            <Form>
                                                <FormGroup>
                                                    <h6>
                                                        Title <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Field
                                                            id="title"
                                                            name="title"
                                                        >{({field}) => <Input {...field} type="text" id="title"
                                                                              name="title"
                                                                              onBlur={handleBlur}
                                                                              onChange={handleChange}
                                                                              placeholder="Enter the property name here (ex: Beachside Villa)"
                                                                              required
                                                                              isValid={touched.name && !errors.name}
                                                                              isInvalid={touched.name && errors.name}/>}
                                                        </Field>
                                                        <FormFeedback invalid>
                                                             {errors.title && <div>{touched.title} || Please enter valid input</div>}
                                                        </FormFeedback>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <MdOutlineVilla/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Price <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Field
                                                            id="price"
                                                            name="price"
                                                            invalid={errors.price && touched.price}
                                                        >{({field}) => <Input {...field}
                                                                              type="number"
                                                                              required
                                                                              placeholder="Enter the value of the property"
                                                        />}</Field>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <i className="fa fa-dollar"/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Location <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Field
                                                            id="location"
                                                            name="location"
                                                            placeholder="Enter the property name here (ex: Beachside Villa)"
                                                            invalid={errors.location && touched.location}
                                                        >{({field}) => <Input {...field}
                                                                              type="text"
                                                                              required
                                                                              placeholder="Enter the property name here (ex: Beachside Villa)"
                                                        />}</Field>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <RiGlobeLine/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Square Footage <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Field
                                                            id="square_footage"
                                                            name="square_footage"
                                                            placeholder="Enter the square footage of the property"
                                                            invalid={errors.square_footage && touched.square_footage}
                                                        >{({field}) => <Input {...field}
                                                                              type="number"
                                                                              required
                                                                              placeholder="Enter the square footage of the property"
                                                        />}</Field>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <GiResize/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row className="num_bedrooms">
                                                        <Col md="6">
                                                            <h6>
                                                                Number of bedrooms <span
                                                                className="icon-danger">*</span>
                                                            </h6>
                                                            <InputGroup className="border-input">
                                                                <Field
                                                                    id="num_bedrooms"
                                                                    name="num_bedrooms"
                                                                    invalid={errors.num_bedrooms && touched.num_bedrooms}
                                                                >{({field}) => <Input {...field}
                                                                                      type="text"
                                                                                      required
                                                                                      placeholder="Enter the number of bedrooms"

                                                                />}</Field>
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>
                                                                        <MdOutlineBedroomChild/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <h6>
                                                                Number of bathrooms <span
                                                                className="icon-danger">*</span>
                                                            </h6>
                                                            <InputGroup className="border-input">
                                                                <Field
                                                                    id="num_bathrooms"
                                                                    name="num_bathrooms"
                                                                    invalid={errors.num_bathrooms && touched.num_bathrooms}
                                                                >{({field}) => <Input {...field}
                                                                                      type="text"
                                                                                      required
                                                                                      placeholder="Enter the number of bathrooms"
                                                                />}</Field>
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>
                                                                        <BiBath/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row>
                                                        <Col md="4">
                                                            <h6>
                                                                Pool
                                                            </h6>
                                                            <InputGroup className="border-input">
                                                                <Field
                                                                    id="pool"
                                                                    name="pool"
                                                                    invalid={errors.pool && touched.pool}
                                                                >{({field}) => <Input {...field}
                                                                                      type="checkbox"/>}</Field>
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>
                                                                        <MdOutlinePool/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md="4">
                                                            <h6>
                                                                Garage
                                                            </h6>
                                                            <InputGroup className="border-input">
                                                                <Field
                                                                    id="garage"
                                                                    name="garage"
                                                                    invalid={errors.garage && touched.garage}
                                                                >{({field}) => <Input {...field}
                                                                                      type="checkbox"/>}</Field>
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>
                                                                        <GiHomeGarage/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md="4">
                                                            <h6>
                                                                CCTV
                                                            </h6>
                                                            <InputGroup className="border-input">
                                                                <Field
                                                                    id="cctv"
                                                                    name="cctv"
                                                                    invalid={errors.cctv && touched.cctv}
                                                                >{({field}) => <Input {...field}
                                                                                      type="checkbox"/>}</Field>
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>
                                                                        <GiCctvCamera/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Main Photo <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Input type="file"
                                                               required
                                                               onChange={e => setUploadFile(e.target.files[0])}

                                                        />
                                                    </InputGroup>
                                                    {uploadFile && (
                                                        <ImagePreview uploadFile={uploadFile}/>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Second Photo <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Input type="file"
                                                               required
                                                               onChange={e => setUploadFile2(e.target.files[0])}
                                                        />
                                                    </InputGroup>
                                                    {uploadFile2 && (
                                                        <ImagePreview2 uploadFile2={uploadFile2}/>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Third Photo <span className="icon-danger">*</span>
                                                    </h6>
                                                    <InputGroup className="border-input">
                                                        <Input type="file"
                                                               required
                                                               onChange={e => setUploadFile3(e.target.files[0])}
                                                        />
                                                    </InputGroup>
                                                    {uploadFile3 && (
                                                        <ImagePreview3 uploadFile3={uploadFile3}/>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <h6>
                                                        Description <span className="icon-danger">*</span>
                                                    </h6>
                                                    <Field
                                                        id="description"
                                                        name="description"
                                                        invalid={errors.description && touched.description}
                                                    >{({field}) => <Input {...field}
                                                                          type="textarea"
                                                                          maxLength="200"
                                                                          placeholder="Enter a description of the property and its contents"
                                                                          rows="13"
                                                                          required
                                                    />}</Field>
                                                    <h5>
                                                        <small>
                                                            <span className="pull-right" id="textarea-limited-message"> 200 characters left </span>
                                                        </small>
                                                    </h5>
                                                    <br/>
                                                    <Row className="buttons-row">
                                                        <Col md="6" sm="6">
                                                            <Button
                                                                block
                                                                className="btn-round"
                                                                color="danger"
                                                                outline
                                                                href="/listings"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                        <Col md="6" sm="6">
                                                            <Button
                                                                block
                                                                className="btn-round"
                                                                color="warning"
                                                                outline
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
