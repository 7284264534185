import {useContext, useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {API} from "../api";
import axios from "axios";
import {AuthContext} from "../contexts/AuthContext";
import {CheckoutForm} from "./CheckoutForm";
import {useNavigate, useParams} from "react-router-dom";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export function Payment() {
    const [estate, setEstate] = useState(null)
    const [clientSecret, setClientSecret] = useState("");
    const [canUpgrade, setCanUpgrade] = useState(false);
    const {user} = useContext(AuthContext)
    const {id} = useParams()
    const navigate = useNavigate()
    let token = ""
    if(user) {
        token = user.token
    }


    useEffect(() => {
        if (estate && !estate.is_owner) {
            navigate('/listings')
        }
        return () => null
    })

    useEffect(() => {
        async function fetchEstate() {
            try {
               const res = await axios.get(API.estates.retrieve(id), {
            headers: {
                "Authorization": `Token ${token}`
            }
        })
                setEstate(res.data)
            } catch(e) {
                console.log(e)
            }
        }

        // Create PaymentIntent when page loads
        async function createPayment() {
            try {
                const res = await axios.post(
                    API.payment.createPayment,
                    {estate_id: id},
                    {headers: {"Authorization": `Token ${token}`}
                    })
                    setClientSecret(res.data.clientSecret)

            } catch (e) {
                console.log(e)
            }
        }
        async function fetchUpgradedEstate() {
            try {
                const res = await axios.get(
                    API.estates.upgradedEstateCount,
                    {headers: {"Authorization": `Token ${token}`}
                    })
                setCanUpgrade(res.data.estate_count < 3)
            } catch (e) {
                console.log(e)
            }
        }

        fetchEstate()
        createPayment()
        fetchUpgradedEstate()

        return () => null

    }, [token, id]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };


    return (
        <>
            {!canUpgrade && (
                <div>
                    <p>There are currently no available premium listings slots available, please try again later.</p>
                </div>
            )}
            {clientSecret && canUpgrade && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm/>
                </Elements>
            )}
        </>
    )
}
