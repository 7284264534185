import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {LoginModal} from "./LoginModal";


// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();
  const {user} = useContext(AuthContext)

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/Modern-apartment-1.jpg") + ")",
        }}

      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Dream Estate</h1>
              <h3 className="description">
                Imagine and find your ideal home.
              </h3>
              <br />
              {!user ? (
                  <Button
                className="btn-round btn-warning btn-lg mr-1"
                size="large"
              >
                <i className="fa fa-home" />
                <LoginModal /> to check our catalog <i className="fa fa-home" />
              </Button>)
               : <Button
                className="btn-round btn-outline-warning btn-lg mr-1"
                size="large"
                href="/listings"
              >
                Check our catalog <i className="fa fa-home" />
              </Button>}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
