import React, {useContext, useState, useEffect} from "react";
import axios from "axios";
import {API} from "../api";
import {AuthContext} from "../contexts/AuthContext";
import {useParams, useNavigate} from "react-router-dom";
import {Button} from "reactstrap";


export function EstateDelete() {
    const navigate = useNavigate()
    const [estate, setEstate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingEstate, setLoadingEstate] = useState(false)
    const {id} = useParams()

    const {user} = useContext(AuthContext)
    let token = ""
    if(user) {
        token = user.token
    }

    useEffect(() => {
        if (estate && !estate.is_owner) {
            navigate('/listings')
        }
        return () => null
    })

    useEffect(() => {
        setLoadingEstate(true)

        function fetchEstate() {
            axios.get(API.estates.retrieve(id), {
            headers: {
                "Authorization": `Token ${token}`
            }
        })
                .then(res => {
                    console.log(res.data)
                    setEstate(res.data)
                })
                .finally(() => {
                    setLoadingEstate(false)
                })
        }

        fetchEstate()
    }, [id, token])


    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        axios.delete(API.estates.delete(id), {
            headers: {
                "Authorization": `Token ${token}`
            }
        })
            .then(res => {
                navigate(`/listings`)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            {loading && "Loading..."}
            {loadingEstate && "Loading listing details..."}
            {estate && (
                <form onSubmit={handleSubmit}>
                    <div className="right-side">
                    <Button
                        className="btn-link"
                        color="danger"
                        type="submit"
                    >
                        Delete
                    </Button>
                    </div>
                </form>
            )}
        </div>
    )
}
