import React, {useEffect, useState, useContext} from "react";
import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    NavLink,
    Col,
    Row,
    Container
} from "reactstrap";
import axios from "axios";
import {API} from "../api";
import {IoBed} from "react-icons/io5";
import {FaBath} from "react-icons/fa";
import {GiCctvCamera, GiHomeGarage, GiResize} from "react-icons/gi";
import {NavLink as ReactLink} from "react-router-dom";
import {AiFillCheckCircle} from "react-icons/ai";
import {CgUnavailable} from "react-icons/cg";
import {AuthContext} from "../contexts/AuthContext";
import LandingPageHeader from "./LandingPageHeader";
import {MdPool} from "react-icons/md";


function ListingItem({estate}) {

    return (
        <>
            {estate.upgraded ? (
                <Card card-plain text-center data-color="brown">
                    <CardImg img src={estate.image} alt={estate.image} top></CardImg>
                    <CardBody>
                        {estate.upgraded && (
                            <span className="mr-1 badge badge-warning badge-pill">
                        <i className="fa fa-star"/>Premium Listing
                        <i className="fa fa-star"/>
                    </span>
                        )}
                        <CardTitle tag="h5">
                            <NavLink tag={ReactLink} to={`/estates/${estate.id}`}>
                           <h3>{estate.title}</h3>
                            </NavLink>
                        </CardTitle>
                        <span className="mr-1 badge badge-warning badge-pill">{estate.location}</span>
                        <CardText>
                            <h4>${estate.price}</h4>
                        </CardText>
                        <ul>
                            <span><IoBed/> {estate.num_bedrooms} Bedrooms |    </span>
                            <span><FaBath/> {estate.num_bathrooms} Bathrooms |  </span>
                            <span><GiResize/> {estate.square_footage} Square Feet  |  </span>
                            {estate.pool ?
                                <span><MdPool /> Swimming Pool <AiFillCheckCircle /> {estate.pool} |    </span> : (
                                    <span><MdPool/> Swimming Pool <CgUnavailable /> {estate.pool}  |    </span> )}
                            {estate.garage ?
                                <span><GiHomeGarage/> Garage <AiFillCheckCircle /> {estate.garage} |    </span> : (
                                    <span><GiHomeGarage/> Garage <CgUnavailable /> {estate.garage}  |    </span> )}
                            {estate.cctv ?
                                <span><GiCctvCamera/> CCTV Surveillance <AiFillCheckCircle /> {estate.cctv} |    </span> : (
                                    <span><GiCctvCamera/> CCTV Surveillance <CgUnavailable /> {estate.cctv}  |    </span> )}
                        </ul>
                        <div>
                            Added on: {new Date(estate.date_created).toDateString()}
                        </div>
                        <Button
                            color="warning"
                            href={`/estates/${estate.id}`}
                        >
                            Show more
                        </Button>
                    </CardBody>
                </Card>

            ) : (

                <Card card-plain card-blog text-center>
                    <CardImg img src={estate.image} alt={estate.image} top></CardImg>
                    <CardBody>
                        {estate.upgraded && (
                            <span className="mr-1 badge badge-warning badge-pill">Premium Listing</span>
                        )}
                        <CardTitle tag="h5">
                            <NavLink tag={ReactLink} to={`/estates/${estate.id}`}>
                                <h3>{estate.title}</h3>
                            </NavLink>
                        </CardTitle>
                        <span className="mr-1 badge badge-warning badge-pill">{estate.location}</span>
                        <CardText >
                           <h4>${estate.price}</h4>
                        </CardText>
                        <ul>
                            <span><IoBed/> {estate.num_bedrooms} Bedrooms |    </span>
                            <span><FaBath/> {estate.num_bathrooms} Bathrooms |  </span>
                            <span><GiResize/> {estate.square_footage} Square Feet  |  </span>
                            {estate.pool ?
                                <span><MdPool /> Swimming Pool <AiFillCheckCircle /> {estate.pool} |    </span> : (
                                    <span><MdPool/> Swimming Pool <CgUnavailable /> {estate.pool}  |    </span> )}
                            {estate.garage ?
                                <span><GiHomeGarage/> Garage <AiFillCheckCircle /> {estate.garage} |    </span> : (
                                    <span><GiHomeGarage/> Garage <CgUnavailable /> {estate.garage}  |    </span> )}
                            {estate.cctv ?
                                <span><GiCctvCamera/> CCTV Surveillance <AiFillCheckCircle /> {estate.cctv} |    </span> : (
                                    <span><GiCctvCamera/> CCTV Surveillance <CgUnavailable /> {estate.cctv}  |    </span> )}


                        </ul>
                        <div>
                            Added on: {new Date(estate.date_created).toDateString()}
                        </div>
                        <Button
                            color="warning"
                            href={`/estates/${estate.id}`}
                        >
                            Show more
                        </Button>
                    </CardBody>
                </Card>
            )}
        </>
    )
}


export function Listings() {
    const {user} = useContext(AuthContext)
    const [estates, setEstates] = useState(null)
    const [upgradedEstates, setUpgradedEstates] = useState(null)
    let token = ""
    if(user) {
        token = user.token
    }

    useEffect(() => {
        function fetchEstates() {
            axios.get(API.estates.list, {
                headers: {
                    "Authorization": `Token ${token}`
                }
            })
                .then(res => {
                    const upgradedEstates = res.data.filter(estate => estate.upgraded)
                    const standardEstates = res.data.filter(estate => !estate.upgraded)
                    setEstates(standardEstates)
                    setUpgradedEstates(upgradedEstates)
                })
        }

        fetchEstates()
        return () => null
    }, [token])


    return (
        <>
            <LandingPageHeader/>
            <div className="wrapper">
                <div className="section text-center landing-section">
                    <Container>
                        <Row>
                            {!estates && "Loading listings..."}
                            {upgradedEstates && upgradedEstates.map(estate => {
                                return (
                                    <Col md="12">
                                        <ListingItem key={estate.id} estate={estate}/>
                                    </Col>
                                )
                            })}
                            {estates && estates.map(estate => {
                                return (
                                    <Col md="12">
                                        <ListingItem key={estate.id} estate={estate}/>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row className="buttons-row">
                            <Col md={{
                                offset: 3,
                                size: 6
                            }}
                                 sm="12">
                                {user ? (
                                    <Button
                                        block
                                        className="btn-round"
                                        color="warning"
                                        outline
                                        href="/create-estate"
                                    >
                                        <i className="fa fa-plus"/>
                                        Create new listing
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

{/*// <Row>*/
}
{/*// {[0, 1,].map(i => (*/
}

{/*//               <Col md="6">*/
}
{/*//                 <Box> <Listings /></Box>*/
}
{/*//               </Col>*/
}
{/*//             ))}*/
}
{/*//           </Row>*/
}
