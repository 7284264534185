import {Button, Modal} from "reactstrap";
import React from "react";
import LandingPageHeader from "./LandingPageHeader";



export function Success() {
    const [smallModal] = React.useState(true);


    return (
        <>
            <LandingPageHeader />
            <Modal
                isOpen={smallModal}
                className="modal-sm"
                modalClassName="bd-example-modal-sm"
            >
                    <div className="modal-header">
                        <h4>Your Listing has been successfully upgraded!</h4>
                        <br/>
                        <Button block className="btn-round" color="warning" href={`/listings`}>
                            Back to Listings
                        </Button>
                    </div>
            </Modal>
        </>
    )

}
