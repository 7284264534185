import React from "react";
import {useNavigate} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    UncontrolledDropdown,
    Navbar,
    Nav,
    Container,
    Button
} from "reactstrap";
import {useContext} from "react"
import {AuthContext} from "../contexts/AuthContext";
import {LoginModal} from "./LoginModal";
import axios from "axios";
import {API} from "../api";
import {SignUpModal} from "./SignUpModal";


// core components

function MultiDropdownNavbar() {
    const {user, logout} = useContext(AuthContext)
    const navigate = useNavigate()
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [bodyClick, setBodyClick] = React.useState(false);
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();

        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 499 ||
                document.body.scrollTop > 499
            ) {
                setNavbarColor("bg-warning");
            } else if (
                document.documentElement.scrollTop < 500 ||
                document.body.scrollTop < 500
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    function handleSubmit() {
        axios.post(API.auth.logout)
            .then(res => {
                logout()
                navigate('/')
            })
    }


    return (
        <>
            {bodyClick ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setBodyClick(false);
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                className={classnames("fixed-top", navbarColor)}
                id="navbar-main"
                expand="lg"
            >
                <Container>
                    <div className="navbar-translate">
                        <button
                            className="navbar-toggler"
                            id="navigation"
                            type="button"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setBodyClick(true);
                                setCollapseOpen(true);
                            }}
                        >
                            <span className="navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>
                    <Collapse navbar isOpen={collapseOpen}>
                        <Nav className="ml-auto" navbar>
                            {/*<UncontrolledDropdown nav inNavbar>*/}
                            {/*    <NavLink tag={ReactLink} to="/about">Contact</NavLink>*/}
                            {/*</UncontrolledDropdown>*/}
                            {/*<UncontrolledDropdown nav inNavbar>*/}
                            {/*    <NavLink tag={ReactLink} to="/about">About Dream Estate</NavLink>*/}
                            {/*</UncontrolledDropdown>*/}
                            {user ? (
                                <UncontrolledDropdown nav inNavbar>
                                    <Button
                                        className="btn-round"
                                        color="warning"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        Logout
                                    </Button>
                                </UncontrolledDropdown>
                            ) : (
                                <UncontrolledDropdown nav inNavbar>
                                    <LoginModal/>
                                </UncontrolledDropdown>
                            )}
                             {user ? null : (
                            <UncontrolledDropdown nav inNavbar>
                                    <SignUpModal />
                                </UncontrolledDropdown>
                                 )}
                            {user ? (
                            <UncontrolledDropdown nav inNavbar>
                                    <Button
                                        className="btn-round"
                                        color="warning"
                                        href='/create-estate'
                                    >
                                        <i className="fa fa-plus" />
                                        Create new listing
                                    </Button>
                                </UncontrolledDropdown>
                                ) : null}
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    )
        ;
}

export default MultiDropdownNavbar;
