const debug = process.env.NODE_ENV !== "production"

let baseURL = "https://dreamestate-app.caprover.dreamestate.rjpx.cc"
const apiURL = `${baseURL}/api`

if (debug){
    baseURL = "http://127.0.0.1:8000"
}



export const API = {
    auth: {
        login: `${baseURL}/dj-rest-auth/login/`,
        logout: `${baseURL}/dj-rest-auth/logout/`,
        passwordReset: `${baseURL}/dj-rest-auth/password/reset/`,
        passwordResetConfirm: `${baseURL}/dj-rest-auth/password/reset/confirm/`,
        signup: `${baseURL}/dj-rest-auth/registration/`,
        verifyEmail: `${baseURL}/dj-rest-auth/registration/verify-email/`,
    },
    payment: {
        createPayment: `${apiURL}/payments/create-payment/`,
    },

    estates: {
        list: `${apiURL}/estates/`,
        create: `${apiURL}/create-estate/`,
        retrieve: id => `${apiURL}/estates/${id}/`,
        update: id => `${apiURL}/estates/${id}/update/`,
        delete: id => `${apiURL}/estates/${id}/delete/`,
        upgradedEstateCount:`${apiURL}/upgraded-estate-count/`,
    }
}
