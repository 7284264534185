import React from "react";
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import {Col, Container, Row} from "reactstrap";

import houseIconPng from "../assets/img/house.png";
import {Icon} from "leaflet";

export function LeafletMap() {

    const houseIcon = new Icon({
        iconUrl: houseIconPng,
        iconSize: [40, 40]
        })

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <MapContainer
                                center={{lat: 25.761545, lng: -80.206276}}
                                zoom={12}
                                style={{height: "50vh", width: "100%"}}
                                scrollWheelZoom={true}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker icon={houseIcon} position={[25.777601, -80.132060]}>
                                    <Popup>
                                        A lovely property <br/> In a lovely location!
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )


}
