import React, {useContext, useState} from "react";
import {Formik, Field, Form} from 'formik';
import axios from "axios";
import {useNavigate, Outlet} from "react-router-dom";
import {API} from "../api";
import {Button, FormGroup, Input, Modal} from "reactstrap";
import {AuthContext} from "../contexts/AuthContext";


export function LoginRequiredModal() {
    const [loginModal, setLoginModal] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const {login} = useContext(AuthContext)
    const navigate = useNavigate()

    function handleSubmit(values) {
        console.log(values)
        setLoading(true)
        axios.post(API.auth.login, values)
            .then(res => {
                login(res.data.token)
                navigate('/listings')
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Button
                className="btn-round"
                color="warning"
                type="button"
                onClick={() => setLoginModal(true)}
            >
                Login
            </Button>
                <Modal
                    isOpen={loginModal}
                    toggle={() => setLoginModal(false)}
                    modalClassName="modal-register"
                >
                    <div className="modal-header no-border-header text-center">
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setLoginModal(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        <h6 className="text-muted">Welcome!</h6>
                        <h3 className="modal-title text-center">DREAM ESTATE LOGIN</h3>
                        <p>Enter your DreamEstate login information below:</p>
                    </div>
                    <div className="modal-body">
                        {loading && "Loading..."}

                        <Formik
                            initialValues={{
                                username: '',
                                password: '',

                            }}
                            onSubmit={handleSubmit}>

                            {({errors, touched}) => (
                                <Form>
                                    <FormGroup>
                                        <label>Username</label>
                                        <Field
                                            id="username"
                                            name="username"
                                            placeholder="Username"
                                            invalid={errors.username && touched.username}
                                        >{({field}) => <Input {...field} type="text"/>}</Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Password</label>
                                        <Field
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            invalid={errors.password && touched.password}
                                        >{({field}) => <Input {...field} type="password"/>}</Field>
                                    </FormGroup>
                                    <Button block className="btn-round" color="warning" type="submit">
                                        Log in
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="modal-footer no-border-footer">
          <span className="text-muted text-center">
            Looking{" "}
              <a href="#pablo" onClick={e => e.preventDefault()}>
              create an account
            </a>
            ?
          </span>
                    </div>
                </Modal>
            <Outlet/>
            </>
            );
            }
