import React, {useEffect, useState} from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {Button, Col, Container, Row} from "reactstrap";



export function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [visible, setVisible] = React.useState();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "http://localhost:3000/payment/success",
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <>
            <div>
                <div className="section mt-5" align="center">
                    <Container>

                        <form id="payment-form" onSubmit={handleSubmit}>
                            <PaymentElement id="payment-element" options={paymentElementOptions}/>
                             <Row className="buttons-row">
                                <Col md="6" sm="6">
                            <Button className="mt-5" size="lg"
                                    block
                                    color="warning"
                                    disabled={isLoading || !stripe || !elements}
                                    id="submit">
                                 <span id="button-text">
                                {isLoading ?
                                    <div className="spinner" id="spinner">

                                    </div> : "Pay now"}
                                 </span>
                            </Button>
                                </Col>
                                 <Col md="6" sm="6">
                            <Button className="mt-5" size="lg"
                                    block
                                    color="danger"
                                    href={`/listings`}
                                    disabled={isLoading || !stripe || !elements}>
                                 <span id="button-text">
                                {isLoading ?
                                    <div className="spinner" id="spinner">

                                    </div> : "Cancel"}
                                 </span>
                            </Button>
                                </Col>
                            </Row>
                            {/* Show any error or success messages */}
                            {message && <div id="payment-message">{message}</div>}
                        </form>

                    </Container>
                </div>
            </div>
        </>

    );
}
