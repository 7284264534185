import React, {useContext} from "react";

import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {AuthContext, AuthContextProvider} from './contexts/AuthContext'
import {Listings} from "./components/Listings";
import {EstateDetail} from "./components/EstateDetail";
import {EstateDelete} from "./components/EstateDelete";
import LandingPage from "./components/LandingPage";
import MultiDropdownNavbar from "./components/MultiDropdownNavbar";
import {EstateCreateView} from "./components/EstateCreateView";
import {EstateUpdateView} from "./components/EstateUpdateView";
import {LoginModal} from "./components/LoginModal";
import {LoginRequiredModal} from "./components/LoginRequiredModal";
import {SignUpModal} from "./components/SignUpModal";
import {ConfirmEmailModal} from "./components/ConfirmEmailModal";
import {Payment} from "./components/Payment";
import {Success} from "./components/Success";
import {LeafletMap} from "./components/leaflet";



function PrivateRoute({children}) {
    const {user} = useContext(AuthContext)
    return user ? children : <Navigate replace to="/login"/>
}

export default function App() {
    return (
        <Router>
            <AuthContextProvider>
                <div>
                    <MultiDropdownNavbar />
                    <Routes>
                        <Route path="/" element={<LandingPage/>}>
                            <Route path="modal" element={<LoginRequiredModal/>}/>
                        </Route>
                        <Route path="/estates/:id" element={<PrivateRoute><EstateDetail/></PrivateRoute>}/>
                        <Route path="/estates/:id/update" element={<PrivateRoute><EstateUpdateView/></PrivateRoute>}/>
                        <Route path="/estates/:id/delete" element={<PrivateRoute><EstateDelete/></PrivateRoute>}/>
                        <Route path="/create-estate" element={<PrivateRoute><EstateCreateView/></PrivateRoute>}/>
                        <Route path="/login" element={<LoginModal/>}/>
                        <Route path="/signup" element={<SignUpModal/>}/>
                        <Route path="/estates/:id/upgrade" element={<PrivateRoute><Payment/></PrivateRoute>}/>
                        <Route path="/accounts/confirm-email/:key" element={<ConfirmEmailModal />}/>
                        <Route path="/listings" element={<Listings/>}/>
                        <Route path="/payment/success" element={<Success/>}/>
                        <Route path="/leaflet" element={<LeafletMap />}/>
                    </Routes>
                </div>
            </AuthContextProvider>
        </Router>
    )
}
