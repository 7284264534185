import React, {useEffect, useState, useContext} from "react";
import axios from "axios";
import {API} from "../api";
import {useParams} from "react-router-dom";

import {
    Button,
    Card,
    CardBody, CardFooter, CardHeader,
    CardTitle,
    Col, Container,
    Row,
    UncontrolledCarousel, UncontrolledCollapse
} from "reactstrap";
import {IoBed} from "react-icons/io5";
import {FaBath} from "react-icons/fa";
import {GiCctvCamera, GiHomeGarage, GiPoolDive, GiResize} from "react-icons/gi";
import {DeleteModal} from "./DeleteModal";
import {AuthContext} from "../contexts/AuthContext";
import {LeafletMap} from "./leaflet";
import {MdPool} from "react-icons/md";
import {AiFillCheckCircle} from "react-icons/ai";
import {CgUnavailable} from "react-icons/cg";


export function EstateDetail() {
    const [estate, setEstate] = useState(null)
    const {id} = useParams()
    const {user} = useContext(AuthContext)
    let token = ""
    if(user) {
        token = user.token
    }

    const [collapseOne, setCollapseOne] = React.useState(false);
    const [collapseTwo, setCollapseTwo] = React.useState(false);
    const [collapseThree, setCollapseThree] = React.useState(false);


    useEffect(() => {
        function fetchEstate() {
            axios.get(API.estates.retrieve(id), {
                headers: {
                    "Authorization": `Token ${token}`
                }
            })
                .then(res => {
                    setEstate(res.data)

                })
        }

        fetchEstate()
    }, [id, token])

    function Carousel() {
        const carouselItems = [
            {
                src: estate.image,
                altText: "Slide 1",
                caption: "Tranquility"
            },
            {
                src: estate.image2,
                altText: "Slide 2",
                caption: "Serenity"
            },
            {
                src: estate.image3,
                altText: "Slide 3",
                caption: "Relaxation"
            }
        ];
        return (
            <>
                <UncontrolledCarousel items={carouselItems}/>
            </>
        );
    }

    return (
        <div>
            {!estate && "Loading listing..."}
            {user && estate && (
                <div>
                    {user && Carousel()}
                    <div className="section">
                        <Container>
                            <CardBody>
                                <CardTitle tag="h2">
                                    {estate.title}
                                </CardTitle>
                                <span className="mr-1 badge badge-warning badge-pill">{estate.location}</span>
                                <hr/>
                                <CardTitle tag="h3">
                                    ${estate.price}
                                </CardTitle>
                                <hr/>
                                <ul>
                                    <span><IoBed/> {estate.num_bedrooms} Bedrooms |    </span>
                                    <span><FaBath/> {estate.num_bathrooms} Bathrooms |  </span>
                                    <span><GiResize/> {estate.square_footage} Square Feet  |  </span>
                                    {estate.pool ?
                                        <span><MdPool/> Swimming Pool <AiFillCheckCircle/> {estate.pool} |    </span> : (
                                            <span><MdPool/> Swimming Pool <CgUnavailable/> {estate.pool} |    </span>)}
                                    {estate.garage ?
                                        <span><GiHomeGarage/> Garage <AiFillCheckCircle/> {estate.garage} |    </span> : (
                                            <span><GiHomeGarage/> Garage <CgUnavailable/> {estate.garage} |    </span>)}
                                    {estate.cctv ?
                                        <span><GiCctvCamera/> CCTV Surveillance <AiFillCheckCircle/> {estate.cctv} |    </span> : (
                                            <span><GiCctvCamera/> CCTV Surveillance <CgUnavailable/> {estate.cctv} |    </span>)}
                                </ul>
                                <hr/>
                                <div>
                                    {estate.description}
                                </div>
                                <hr/>
                                <div>
                                    Added on: {new Date(estate.date_created).toDateString()}
                                </div>
                            </CardBody>

                            <Row className="card-body-row">
                                <Col md="4" sm="4">
                                    <div className="info">
                                        <div className="icon icon-warning">
                                            <i className="nc-icon nc-watch-time"/>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Swift Follow-up</h4>
                                            <p>
                                                We can schedule you a visit to this property on the same day you reach
                                                us!
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" sm="4">
                                    <div className="info">
                                        <div className="icon icon-warning">
                                            <i className="nc-icon nc-zoom-split"/>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">Zero Commitment</h4>
                                            <p>
                                                Visiting this property doesn't involve any pre-commitment, we want you
                                                to be sure this is the one first!
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" sm="4">
                                    <div className="info">
                                        <div className="icon icon-warning">
                                            <i className="nc-icon nc-diamond"/>
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title">The Perfect Choice</h4>
                                            <p>
                                                We'll be here to help you make the perfect choice for your new dream
                                                home!
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr/>
                            <p>
                                Are you interested in knowing more about this property?{" "}
                                <a
                                    className="link-danger"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    Contact Us!
                                </a>
                            </p>
                            <hr/>
                            <div className="faq">
                                <h4>Frequently Asked Questions</h4>
                                <br/>
                                <div id="acordeon">
                                    <div aria-multiselectable={true} id="accordion" role="tablist">
                                        <Card className="no-transition">
                                            <CardHeader
                                                className="card-collapse"
                                                id="headingOne"
                                                role="tab"
                                            >
                                                <h5 className="mb-0 panel-title">
                                                    <a
                                                        aria-expanded={collapseOne}
                                                        href="#pablo"
                                                        id="collapseOne"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCollapseOne(!collapseOne);
                                                        }}
                                                    >
                                                        How can I schedule a visit to this property?{" "}
                                                        <i className="nc-icon nc-minimal-down"/>
                                                    </a>
                                                </h5>
                                            </CardHeader>
                                            <UncontrolledCollapse
                                                aria-labelledby="headingOne"
                                                role="tabpanel"
                                                toggler="#collapseOne"
                                            >
                                                <CardBody>

                                                </CardBody>
                                            </UncontrolledCollapse>
                                            <CardHeader
                                                className="card-collapse"
                                                id="headingTwo"
                                                role="tab"
                                            >
                                                <h5 className="mb-0 panel-title">
                                                    <a
                                                        aria-expanded={collapseTwo}
                                                        href="#pablo"
                                                        id="collapseTwo"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCollapseTwo(!collapseTwo);
                                                        }}
                                                    >
                                                        What information do I need to provide to schedule a visit?{" "}
                                                        <i className="nc-icon nc-minimal-down"/>
                                                    </a>
                                                </h5>
                                            </CardHeader>
                                            <UncontrolledCollapse
                                                aria-labelledby="headingTwo"
                                                role="tabpanel"
                                                toggler="#collapseTwo"
                                            >
                                                <CardBody>

                                                </CardBody>
                                            </UncontrolledCollapse>
                                            <CardHeader
                                                className="card-collapse"
                                                id="headingThree"
                                                role="tab"
                                            >
                                                <h5 className="mb-0 panel-title">
                                                    <a
                                                        aria-expanded={collapseThree}
                                                        href="#pablo"
                                                        id="collapseThree"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCollapseThree(!collapseThree);
                                                        }}
                                                    >
                                                        Is it possible to visit the property on the weekend?{" "}
                                                        <i className="nc-icon nc-minimal-down"/>
                                                    </a>
                                                </h5>
                                            </CardHeader>
                                            <UncontrolledCollapse
                                                aria-labelledby="headingThree"
                                                role="tabpanel"
                                                toggler="#collapseThree"
                                            >
                                                <CardBody>

                                                </CardBody>
                                            </UncontrolledCollapse>
                                        </Card>
                                    </div>
                                    {/* end acordeon */}
                                </div>
                            </div>
                            <LeafletMap/>
                            <hr/>
                            {estate.is_owner && (
                                 <div className="pricing-1 section">
                                    <Container>
                                        <Row>
                                            <Col className="ml-auto mr-auto text-center" md="6">
                                                <h2 className="title">Upgrade your listing</h2>
                                                <h5 className="description">
                                                    Rapidly boost your listing views and interest with one of our
                                                    upgrade packages to find potential buyers!
                                                </h5>
                                            </Col>
                                        </Row>
                                        <div className="space-top"/>
                                        <Row>
                                            <Col md="3">
                                                <Card className="card-pricing" data-color="green">
                                                    <CardBody>
                                                        <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-air-baloon"/>
                      </span>
                                                        </div>
                                                        <CardTitle tag="h3">$9.99</CardTitle>
                                                        <p className="card-description">
                                                            Showcase your listing among top searches for maximum visibility for 3 days!
                                                        </p>
                                                        <CardFooter>
                                                             <Button
                                                                className="btn-warning btn-round"
                                                                color="default"
                                                                href={`/estates/${id}/upgrade`}
                                                            >
                                                                <i className="fa fa-star"/>Upgrade<i
                                                                className="fa fa-star"/>
                                                            </Button>
                                                        </CardFooter>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="3">
                                                <Card className="card-pricing" data-color="blue">
                                                    <CardBody>
                                                        <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-trophy"/>
                      </span>
                                                        </div>
                                                        <CardTitle tag="h3">$14.99</CardTitle>
                                                        <p className="card-description">
                                                            Showcase your listing among top searches for maximum visibility for 7 days!
                                                        </p>
                                                        <CardFooter>
                                                            <Button
                                                                className="btn-warning btn-round"
                                                                color="default"
                                                                href={`/estates/${id}/upgrade`}
                                                            >
                                                                <i className="fa fa-star"/>Upgrade<i
                                                                className="fa fa-star"/>
                                                            </Button>
                                                        </CardFooter>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="3">
                                                <Card className="card-pricing" data-color="orange">
                                                    <CardBody>
                                                        <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-diamond"/>
                      </span>
                                                        </div>
                                                        <CardTitle tag="h3">$19.99</CardTitle>
                                                        <p className="card-description">
                                                            Showcase your listing among top searches for maximum visibility for 10 days!
                                                        </p>
                                                        <CardFooter>
                                                            <Button
                                                                className="btn-warning btn-round"
                                                                color="default"
                                                                href={`/estates/${id}/upgrade`}
                                                            >
                                                                <i className="fa fa-star"/>Upgrade<i
                                                                className="fa fa-star"/>
                                                            </Button>
                                                        </CardFooter>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="3">
                                                <Card className="card-pricing" data-color="purple">
                                                    <CardBody>
                                                        <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-istanbul"/>
                      </span>
                                                        </div>
                                                        <CardTitle tag="h3">$24.99</CardTitle>
                                                        <p className="card-description">
                                                            Showcase your listing among top searches for maximum visibility for 14 days!
                                                        </p>
                                                        <CardFooter>
                                                             <Button
                                                                className="btn-warning btn-round"
                                                                color="default"
                                                                href={`/estates/${id}/upgrade`}
                                                            >
                                                                <i className="fa fa-star"/>Upgrade<i
                                                                className="fa fa-star"/>
                                                            </Button>
                                                        </CardFooter>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                 )}
                            <Row className="buttons-row">
                                <Col md="4" sm="4">
                                    <Button color="warning" type="button" block
                                            className="btn-round" href={`/listings`}>
                                        <span><i className="fa fa-arrow-left"/> Back to Listings</span>
                                    </Button>
                                </Col>
                                <Col md="4" sm="4">
                                    {estate.is_owner && (
                                        <Button color="warning" type="button" block
                                                className="btn-round" href={`/estates/${id}/update`}>
                                            <i className="fa fa-edit"/> Update
                                        </Button>
                                    )}
                                </Col>
                                {/*<Col md="3" sm="3">*/}
                                {/*    {estate.is_owner && (*/}
                                {/*        <Button color="warning" type="button" block*/}
                                {/*                className="btn-round" href={`/estates/${id}/upgrade`}>*/}

                                {/*            <i className="fa fa-star"/> Upgrade Listing*/}
                                {/*        </Button>*/}
                                {/*    )}*/}
                                {/*</Col>*/}
                                <Col md="4" sm="4">
                                    {estate.is_owner && (
                                        <div>
                                            <DeleteModal/>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}
        </div>
    )
}
